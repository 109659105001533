<template>
    <div class="CloudcoachReportBySchool">

        <div class="search" style="display: block">
            <a-tree-select
                    class="search-style"
                    style="width: 300px"
                    v-model="OrgIds"
                    :tree-data="treeData"
                    tree-checkable
                    :maxTagCount="1"
                    placeholder="请选择搜索范围"
                    @change="change"
                    :show-checked-strategy="SHOW_PARENT"
            />
            <!--:show-checked-strategy="SHOW_PARENT"-->
            <a-range-picker class="search-style" style="width: 214px" :default-value="[moment(startSignDate, dateFormat), moment(endSignDate, dateFormat)]" 
                    :format="dateFormat"  :placeholder="['开始时间','结束时间']"   allowClear @change="ChangeSearchTime"  />
            <a-button class="search-style" @click="_clear">重置</a-button>
            <a-button type="primary" @click="_search">查询</a-button>
        </div>
        <a-table  :pagination="false" :columns="columns" :data-source="data"  :scroll="{ y: 500 }" >
            <div slot="AvePeopleDuration"  slot-scope="text, record, index" >
                {{Math.floor(text * 100)/100}}
            </div>
        </a-table>

        <div
                v-show="spinning"
                style="position:fixed;left:50%;"
        >

            <a-drawer
                    :title="visibleName"
                    placement="right"
                    :visible="visible"
                    :width="600"
                    @close="onClose"
            >
                <div id="TopSelling"></div>
            </a-drawer>
            <a-drawer
                    :title="visibleName"
                    placement="right"
                    :visible="visible2"
                    :width="1000"
                    @close="onClose2"
            >
                <div id="container"></div>
            </a-drawer>


            <a-spin style="margin: 150px 0%;">
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;color:red;"
                        spin
                />
            </a-spin>
        </div>
        
    </div>
</template>

<script>
    import G2 from '@antv/g2';
    // import { TreeSelect } from 'ant-design-vue';
    import moment from 'moment';
    export default {
        name: "CloudcoachReportBySchool",
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                columns :[
                    {
                        title: '中心',
                        dataIndex: 'OrgName',
                        key: 'OrgName',
                        align: 'left',
                        width:150
                        
                    },
                    {
                        title: '已开通体验',
                        dataIndex: 'in_count',
                        key: 'in_count',
                        align: 'center',
                        width:'8%'

                    },
                    {
                        title: '体验人数',
                        dataIndex: 'InedPeopleCount',
                        key: 'InedPeopleCount',
                        align: 'center',
                        width:'8%'
                    },
                    {
                        title: '每日完成率(%)',
                        dataIndex: 'completeRateByday',
                        key: 'completeRateByday',
                        align: 'center',
                        width:'8%',
                        scopedSlots: { customRender: 'completeRateByday' },
                    },
                    {
                        title: '人均日时长(分)',
                        dataIndex: 'AvePeopleDuration',
                        key: 'AvePeopleDuration',
                        align: 'center',
                        width:'8%',
                        scopedSlots: { customRender: 'AvePeopleDuration' },
                    }
                ],
                data:[],
                startSignDate:'',
                endSignDate:'',
                spinning:false,
                visibleName:'',
                visible:false,
                visible2:false,
                treeData:[
                    {
                        title: 'Node1',
                        value: '7520669f3b20008c6a6df71e9cebc2f9',
                        key: '3',
                        children: [
                            {
                                title: 'Child Node1',
                                value: '7520669f3b20008c6a6df71e9cebc2f944',
                                key: '4',
                            },
                        ],
                    },
                    {
                        title: 'Node2',
                        value: '6',
                        key: '7',
                        children: [
                            {
                                title: 'Child Node3',
                                value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
                                key: '8',
                            },
                            {
                                title: 'Child Node4',
                                value: '7520669f3b20008c6a6df71e9cebc2f9wee',
                                key: '9',
                            },
                            {
                                title: 'Child Node5',
                                value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
                                key: '1',
                            },
                        ],
                    },
                ],

                OrgIds: [],
                OrgName:[],
                ParentId:[],
                SHOW_PARENT:'SHOW_ALL',
                dateFormat:"YYYY-MM-DD",
            }
        },
        created(){
            this.startSignDate = moment(new Date()).add(-1, 'days').format(this.dateFormat);
            this.endSignDate = moment(new Date()).format(this.dateFormat);
            this._treeData()
            this.loadData();
        },
        methods:{
            moment,
            _treeData(){
                let self = this;
                this.$axios.get(1090,{
                    UserId:self.userinfo.uid,
                },res=>{
                    self.treeData = res.data;
                    console.log(res);
                })
            },
            change(value, label,text){
                if(text.checked){
                    this.ParentId.push(text.triggerValue);
                }else{
                    if(this.ParentId.length>0){
                        for (var i=0;i<this.ParentId.length;i++){
                            if(text.triggerValue==this.ParentId[i]){
                                this.ParentId.splice(i,1)
                            }
                        }
                    }
                }
                
                this.OrgName = label;
                this.OrgIds = value;
            },
            loadData(){
                let self = this;
                this.$message.loading('数据获取中...',0);
                this.$axios.post(7000009,{
                    startSignDate:self.startSignDate,
                    endSignDate:self.endSignDate,
                    OrgIds:JSON.stringify(self.OrgIds),
                    OrgName:JSON.stringify(self.OrgName),
                    ParentId:JSON.stringify(self.ParentId),
                },res=>{
                    if (res.data.code == 1) {
                        self.data = res.data.data;
                    } else {
                        self.data = [];
                    }
                    
                    this.$message.destroy();
                })
            },
            _search(){
                this.loadData();
            },
            _clear(){
                this.OrgIds =[];
                this.OrgName = [];
                this.startSignDate = '';
                this.endSignDate   = '';
                this.ParentId =[];
                this.loadData();
            },
            ChangeSearchTime(time, timeString){
                this.startSignDate = timeString[0];
                this.endSignDate   = timeString[1];
            },
            onClose(){
                this.visibleName ='';
                this.visible = false;
            },
            onClose2(){
                this.visibleName ='';
                this.visible2 = false;
            },
            __floor(number){
                return Math.floor(number * 100)/100;
            }
        }
    }
</script>

<style scoped lang="less">
    .CloudcoachReportBySchool{
        background: #ffffff;
        margin-top: 15px;
        min-height: 800px;
        padding: 15px;
    }
    .search{
        margin-bottom: 20px;
        .search-style{
            display: inline-block;
            width: 120px;
            margin-right: 15px;
        }
    }
   
</style>